import { get } from './storage';
import { post } from './api';

const TEACHER_STORAGE_KEY = 'logged_in_teacher';
const STUDENT_STORAGE_KEY = 'logged_in_student';


export const isTeacherLoggedIn = (): string | null => {
  const teacherCode = get(TEACHER_STORAGE_KEY);
  return teacherCode;
};

export const isStudentLoggedIn = (): string | null | undefined => {
  const studentCode = get(STUDENT_STORAGE_KEY);
  return studentCode;
};

export const getClassAsStudent = async (code?: string) => {
  try {
    const classData = await post('/getCode', {
      code,
    })

    window && window.localStorage.setItem('class_type', classData.level);
    return classData
  } catch (error) {
    console.error(error.message);
  }
};

export const getCurrentClassType = (): string | undefined => {
  let code = isStudentLoggedIn();

  if (!code) {
    code = isTeacherLoggedIn();
  }

  if (!code) return undefined;

  if (window) code = window.localStorage.getItem('class_type');

  return code;
};
