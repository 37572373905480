import React, { useState } from "react";
import { Link } from "gatsby";

import "@fontsource/alata";
import "@fontsource/asap";

import {
  WebsiteDateModal,
  ContactInformationModal,
  InformationModalOpener,
} from "~/components/modals";
import { borderStyle } from "polished";

interface ContainsModals {
  modalFunctions: ((arg0: boolean) => void)[];
  modalRatings: number[];
}

export const Header = (): JSX.Element => {
  return (
    <div className="bg-white">
      <div className="flex flex-col md:flex-row flex-wrap md:justify-between md:ml-20 mt-2 md:mt-12 mb-6 text-3xl">
        <h1 className="text-safe-environment-indigo-900 mt-4 ml-4 float-right md:mt-0 lg:flex-wrap md:w-2/5 lg:w-1/3 lg:pr-12 xl:mr-0 xl:w-1/4 2xl:w-1/6">
          Foundation for a Safe Environment
        </h1>
        <a className="font-black mr-6 ml-6 flex content-end mt-4 justify-end md:mt-0" href="#" style={{ fontFamily: "Asap" }}>
          www.fsafeenviron.org
        </a>
      </div>
      <div className="bg-safe-environment-blue-800  flex justify-end">
        <Link to="/article/safe-environment" className="text-lg text-white sm:py-2 lg:py-4 md:px-12 xl:px-32 px-3">Home</Link>
        <Link className="text-lg text-white sm:py-2 lg:py-4" to="/article/safe-environment/about-us">About Us</Link>
        <Link className="text-lg text-white sm:py-2 lg:py-4 md:px-12 xl:px-32 px-4" to="/article/safe-environment/contact-us">Contact Us</Link>
      </div>
    </div>
  );
};

const Body = ({modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="flex flex-col text-black h-screen justify-items-center ml-20 mt-10 relative">
      <h2 className="text-3xl">Contact Us</h2>
      <span>Contact us to ask a question or to make a</span>
      <span>tax-deductible donation.</span>
      <span>publicaffairs@fsafeenviron.org</span>
      <span>(800) 555-3451</span>
      <InformationModalOpener
        className="absolute top-6 left-40"
        rating={modalRatings[0]}
        onClick={() => modalFunctions[0](true)}
      />
      <InformationModalOpener
        className="mt-10"
        rating={modalRatings[1]}
        onClick={() => modalFunctions[1](true)}
      />
    </div>
  );
};

const SafeEnvironmentContactUs = (): JSX.Element => {
  const [websiteDateModalVisible, setWebsiteDateModalVisible] = useState<boolean>(false);
  const [websiteDateModalRating, setWebsiteDateModalRating] = useState<number>(-1);
  const [contactInformationModalVisible, setContactInformationModalVisible] = useState<boolean>(false);
  const [contactInformationModalRating, setContactInformationModalRating] = useState<number>(-1);

  return (
    <div style={{ fontFamily: "Alata" }}>
      <Header />
      <Body modalFunctions={[setContactInformationModalVisible,setWebsiteDateModalVisible]} modalRatings={[contactInformationModalRating,websiteDateModalRating]} />
      {websiteDateModalVisible && (
        <WebsiteDateModal
          modalRating={websiteDateModalRating}
          modalVisible={websiteDateModalVisible}
          setModalRating={setWebsiteDateModalRating}
          setModalVisible={setWebsiteDateModalVisible}
        />
      )}
      {(contactInformationModalVisible &&
        <ContactInformationModal
          modalRating={contactInformationModalRating}
          modalVisible={contactInformationModalVisible}
          setModalRating={setContactInformationModalRating}
          setModalVisible={setContactInformationModalVisible}
        />
      )}
    </div>
  )
};

export default SafeEnvironmentContactUs;
