import { useState, useEffect } from 'react';
import { getCurrentClassType } from '~/helpers/lessonHelpers';
import { schoolTypes } from '~/consts/types';

export const useLesson = () => {
  const [type, setType] = useState<string>(schoolTypes.middle);

  useEffect(() => {
    const classType = getCurrentClassType();

    if (classType) {
      setType(classType);
    }
  }, []);

  const color = type.toLowerCase();

  return {
    type,
    color,
  };
};

export default useLesson;
