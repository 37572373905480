import React, { HTMLProps } from 'react';
import clsx from 'clsx'

import { useLesson } from '~/hooks/useLesson';
interface H1Props extends HTMLProps<HTMLHeadingElement> {
  underline?: boolean;
  color?: string
}

export const H1 = ({
  children,
  underline = false,
  ...props
}: H1Props) => {
  const { color } = useLesson();
  const isMs = props.color ? props.color === 'ms' : color === 'ms'
  return (
    <h1
      className={clsx("text-darkBlue text-2xl font-bold after:block leading-10", {
         'after:bg-purple-600': isMs,
         'after:bg-indigo-600': !isMs,
         'after:w-8 after:h-1 after:rounded': underline
      })}
      {...props}
    >
      {children}
    </h1>
  );
};

export default H1;
